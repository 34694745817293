var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'staffNumber'),"label":_vm.$t('hr.employee.employment.staff_number'),"rules":{
              required: true,
              numeric: true,
              min_value: 0,
              max_value: 2147483647
            },"index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","max":"2147483647","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.staffNumber),callback:function ($$v) {_vm.$set(_vm.employeePayload, "staffNumber", _vm._n($$v))},expression:"employeePayload.staffNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'integrationValue'),"label":_vm.$t('hr.employee.employment.integration_value'),"rules":"max:255","index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.integrationValue),callback:function ($$v) {_vm.$set(_vm.employeePayload, "integrationValue", $$v)},expression:"employeePayload.integrationValue"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'branch.name'),"label":_vm.$t('hr.employee.employment.branch'),"rules":"required","index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('branch-picker',{staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.branch),callback:function ($$v) {_vm.$set(_vm.employeePayload, "branch", $$v)},expression:"employeePayload.branch"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'unit.name'),"label":_vm.$t('hr.employee.employment.unit'),"rules":"required","index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('unit-picker',{staticStyle:{"max-width":"300px"},attrs:{"item-value":"id","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.unit),callback:function ($$v) {_vm.$set(_vm.employeePayload, "unit", $$v)},expression:"employeePayload.unit"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'team.name'),"label":_vm.$t('hr.employee.employment.team'),"index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('team-picker',{staticStyle:{"max-width":"300px"},attrs:{"item-value":"id","clearable":"","outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.team),callback:function ($$v) {_vm.$set(_vm.employeePayload, "team", $$v)},expression:"employeePayload.team"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'jobInfo.name'),"label":_vm.$t('hr.employee.employment.job_info'),"index":5,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('job-info-picker',{staticStyle:{"max-width":"300px"},attrs:{"item-value":"id","clearable":"","outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.jobInfo),callback:function ($$v) {_vm.$set(_vm.employeePayload, "jobInfo", $$v)},expression:"employeePayload.jobInfo"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'employmentType')
                ? _vm.$t(("employment_type." + (_vm.$helpers.get(_vm.employee, 'employmentType').toLowerCase())))
                : null,"label":_vm.$t('hr.employee.employment.employment_type'),"index":6,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('employment-types-picker',{staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","color":"tertiary","clearable":""},model:{value:(_vm.employeePayload.employmentType),callback:function ($$v) {_vm.$set(_vm.employeePayload, "employmentType", $$v)},expression:"employeePayload.employmentType"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'collarType')
                ? _vm.$t(("collar_type." + (_vm.$helpers.get(_vm.employee, 'collarType').toLowerCase())))
                : null,"label":_vm.$t('hr.employee.employment.collar_type'),"index":7,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('collar-types-picker',{staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","color":"tertiary","clearable":""},model:{value:(_vm.employeePayload.collarType),callback:function ($$v) {_vm.$set(_vm.employeePayload, "collarType", $$v)},expression:"employeePayload.collarType"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'agency.name'),"label":_vm.$t('hr.employee.employment.agency'),"index":8,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('agency-picker',{staticStyle:{"max-width":"300px"},attrs:{"item-value":"id","clearable":"","outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.agency),callback:function ($$v) {_vm.$set(_vm.employeePayload, "agency", $$v)},expression:"employeePayload.agency"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'company.name'),"label":_vm.$t('hr.employee.employment.company'),"index":9,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('company-picker',{staticStyle:{"max-width":"300px"},attrs:{"item-value":"id","clearable":"","outlined":"","dense":"","color":"tertiary"},model:{value:(_vm.employeePayload.company),callback:function ($$v) {_vm.$set(_vm.employeePayload, "company", $$v)},expression:"employeePayload.company"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'ssn'),"label":_vm.$t('hr.employee.employment.ssn'),"rules":"max:50","index":10,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.ssn),callback:function ($$v) {_vm.$set(_vm.employeePayload, "ssn", $$v)},expression:"employeePayload.ssn"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'socialSecurityType')
                ? _vm.$t(("social_security_type." + (_vm.$helpers.get(_vm.employee, 'socialSecurityType'))))
                : null,"label":_vm.$t('hr.employee.employment.social_security_type'),"index":11,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('social-security-type-picker',{staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","color":"tertiary","clearable":""},model:{value:(_vm.employeePayload.socialSecurityType),callback:function ($$v) {_vm.$set(_vm.employeePayload, "socialSecurityType", $$v)},expression:"employeePayload.socialSecurityType"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'hiredDate'),"label":_vm.$t('hr.employee.employment.hired_date'),"rules":"required","index":12,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('date-picker',{attrs:{"outlined":"","dense":"","color":"tertiary","styles":"max-width: 200px;","error-messages":errors},model:{value:(_vm.employeePayload.hiredDate),callback:function ($$v) {_vm.$set(_vm.employeePayload, "hiredDate", $$v)},expression:"employeePayload.hiredDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'startedDate'),"label":_vm.$t('hr.employee.employment.started_date'),"index":13,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('date-picker',{attrs:{"outlined":"","dense":"","color":"tertiary","styles":"max-width: 200px;"},model:{value:(_vm.employeePayload.startedDate),callback:function ($$v) {_vm.$set(_vm.employeePayload, "startedDate", $$v)},expression:"employeePayload.startedDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'allowanceDate'),"label":_vm.$t('hr.employee.employment.allowance_date'),"rules":"required","index":14,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('date-picker',{attrs:{"outlined":"","dense":"","color":"tertiary","styles":"max-width: 200px;","error-messages":errors},model:{value:(_vm.employeePayload.allowanceDate),callback:function ($$v) {_vm.$set(_vm.employeePayload, "allowanceDate", $$v)},expression:"employeePayload.allowanceDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'researchAndDevelopmentPerson') ? _vm.$t('buttons.yes') : _vm.$t('buttons.no'),"label":_vm.$t('hr.employee.employment.research_and_development_person'),"index":15,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",model:{value:(_vm.employeePayload.researchAndDevelopmentPerson),callback:function ($$v) {_vm.$set(_vm.employeePayload, "researchAndDevelopmentPerson", $$v)},expression:"employeePayload.researchAndDevelopmentPerson"}})]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }